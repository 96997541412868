import React from 'react';
import marked from 'marked';
import badgeImg from '../../img/price icon.png';

const TimeAccessProduct = ({ timeAccessProductData, setParams }) => {
  const {
    name,
    price,
    duration,
    image,
    icon,
    description,
    script
  } = timeAccessProductData.fields;

  const img = image.fields.file.url;
  const iconImage = icon.fields.file.url;

 

  const actualDescription = marked(description);

  return (
    <div className="product-listing ">
      <span className="product-listing__mobile-info">?</span>
      <div className="product__badge">
        <div className="badge__background" />
        <img src={badgeImg} alt="" className="product__badge--img" />
        <div>{price} zł</div>
        <div>{duration}</div>
      </div>
      <div className="text-title">
        <div className="title-background" />
        <h2>
          {/* <img src={ icon } alt="" className="title-icon" /> */}
          <div>
            <img src={iconImage}></img>
            <span>{ name }{ script ? ' +\u00a0skrypt' : '' }</span>
            
          </div>
        </h2>
      </div>
      <div className="product__image--wrapper">
        <img src={img} alt="" className="product__image" />
      </div>
      <div className="product-listing__inner-wrapper">
        <div className="product-background" />
        <section
          className="product-listing-description"
          dangerouslySetInnerHTML={{ __html: actualDescription }}
        ></section>

        <div className="tile__footer">
          {/*Cena: {price}zł*/}
          
          {/*<p
            onClick={() =>
              setParams({
                type: `${name}${script ? ' + skrypt' : ''}`,
                paymentChoice: 'Płatność jednorazowa'
              })
            }

            className="button-link  button-link__secondary--reversed btn grid-content-right button-link__space-standard"
          >
            Kup teraz
          </p>*/}
          <p
            className="button-link  button-link__secondary--reversed btn grid-content-right button-link__space-standard"
          >
            Niedostępny
          </p>
        </div>
      </div>
    </div>
  );
};

export default TimeAccessProduct;
